import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { rootReducer } from './features/root-reducer';
const composeEnhancers = (process.env.NODE_ENV === 'development' &&
    window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
export const browserHistory = createBrowserHistory();
export const routerMiddleware = createRouterMiddleware(browserHistory);
function configureStore(initialState) {
    const middlewares = [
        routerMiddleware,
        thunk,
        logger,
    ];
    const enhancer = composeEnhancers(applyMiddleware(...middlewares));
    return createStore(rootReducer(browserHistory), initialState, enhancer);
}
export const store = configureStore();
export default store;
