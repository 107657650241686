import * as React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import messages_de from '../locales/de.json';
import messages_en from '../locales/en.json';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de';
import PdfCheck from './views/pdfCheck';
const messages = {
    de: messages_de,
    en: messages_en,
};
class LocalizedApp extends React.Component {
    render() {
        const language = this.props.language;
        return (React.createElement(IntlProvider, { locale: language, messages: messages[language] },
            React.createElement(Route, { exact: true, path: "/", component: PdfCheck })));
    }
}
const mapStateToProps = (state) => ({ language: state.language });
export default connect(mapStateToProps)(LocalizedApp);
