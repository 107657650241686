import axios from 'axios';
const cfg = {
    x509: {
        enabled: false,
    },
};
export const getConfig = () => axios.get('/config.json')
    .then((resp) => {
    console.log('config:', resp.data);
    Object.assign(cfg, resp.data);
    return cfg;
})
    .catch((err) => {
    console.log('error fetching config:', err);
});
export default cfg;
