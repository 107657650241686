import * as React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import LocalizedApp from './localizedapp';
export class App extends React.Component {
    render() {
        const { store, history } = this.props;
        return (React.createElement(Provider, { store: store },
            React.createElement(ConnectedRouter, { history: history },
                React.createElement(LocalizedApp, null))));
    }
}
