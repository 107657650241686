export default [
// Procivis Test certificate
`-----BEGIN CERTIFICATE-----
MIID/zCCAuegAwIBAgIJAKu9R9y80QJDMA0GCSqGSIb3DQEBCwUAMIGVMQswCQYD
VQQGEwJaSDEPMA0GA1UECAwGWnVyaWNoMQ8wDQYDVQQHDAZadXJpY2gxFDASBgNV
BAoMC1Byb2NpdmlzIEFHMRIwEAYDVQQLDAllSUQrIHRlc3QxGTAXBgNVBAMMEFBy
b2NpdmlzIEFHIFRlc3QxHzAdBgkqhkiG9w0BCQEWEGN1YmFAcHJvY2l2aXMuY2gw
HhcNMTkwMjIxMTAyMjMxWhcNMjUwMjE5MTAyMjMxWjCBlTELMAkGA1UEBhMCWkgx
DzANBgNVBAgMBlp1cmljaDEPMA0GA1UEBwwGWnVyaWNoMRQwEgYDVQQKDAtQcm9j
aXZpcyBBRzESMBAGA1UECwwJZUlEKyB0ZXN0MRkwFwYDVQQDDBBQcm9jaXZpcyBB
RyBUZXN0MR8wHQYJKoZIhvcNAQkBFhBjdWJhQHByb2NpdmlzLmNoMIIBIjANBgkq
hkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxJhr5pO+YQ8KwR5VmbcCl6SYOcbqkTTg
6CFAk6bT7xyCNfDRuiQ6jM9IC4oFT/QR0g5GZjOASn1/cOS+18ssSP3tu4GI+6BA
PyIZtNw1kcHWLrpGYekEIWylnfKh6AShF1NJjki7FWgPuh9b1/D6d7zW4ScCF2K3
98WQenf6uWMV9NQkNPBvEFAhtzoFed/pG9H4aJn+btWZvqg4TQLsYjaF3dOuOSO7
F7nmYkV/3btkfG5PD2uJ8qQ6dIysPA2ASghZtIJHPRr0/NGZbnbw8LBfGsTSNYAY
+mwkQZVaLOBlYoFaz+dfCkZTJOgtmX3QLgUnRBieACWXQ0pZl8EdCwIDAQABo1Aw
TjAdBgNVHQ4EFgQU/abtbty501X/c7a7ZzKLWe808DswHwYDVR0jBBgwFoAU/abt
bty501X/c7a7ZzKLWe808DswDAYDVR0TBAUwAwEB/zANBgkqhkiG9w0BAQsFAAOC
AQEATIl+zQMcwP7NKenuXUjZZDVz5iDBlQW5YEiTHlcliIVsfggpp9e5/PUwq3u3
Xf+MZRjOF/5fPhR+sG4AQc8xh8vLUqy+OOy6gwZjm0U8AMEW19/6aeVR0rKPT2VI
JKXbTo/PMCqC3kgcDpuiS6U9TKw618H7nTVarnxotxSHqipp9hrhHBeZxi63G74T
sah39zMp5RiOLWZp6fCubZkEgZRI3PDrohJdBwFErvup/yNEGPu3M5tHDj47edwC
Qp4w9hXHfYMIgUO5gghxPy/rbBVmvy7ho0tI6WFf+oJtai9T/BQ+RU7vjPTKsD2C
BNq6HZkfSoYUbAija23oWm/nXQ==
-----END CERTIFICATE-----
`,
// Procivis demo certificate
`-----BEGIN CERTIFICATE-----
MIIDmjCCAoICCQDIAS0spH/MWzANBgkqhkiG9w0BAQsFADCBjjELMAkGA1UEBhMC
Q0gxDzANBgNVBAgMBlp1cmljaDEPMA0GA1UEBwwGWnVyaWNoMRQwEgYDVQQKDAtQ
cm9jaXZpcyBBRzENMAsGA1UECwwEZUlEKzEUMBIGA1UEAwwLUHJvY2l2aXMgQUcx
IjAgBgkqhkiG9w0BCQEWE3N1cHBvcnRAcHJvY2l2aXMuY2gwHhcNMTkwMjIwMTI1
MDMzWhcNMjUwMjE4MTI1MDMzWjCBjjELMAkGA1UEBhMCQ0gxDzANBgNVBAgMBlp1
cmljaDEPMA0GA1UEBwwGWnVyaWNoMRQwEgYDVQQKDAtQcm9jaXZpcyBBRzENMAsG
A1UECwwEZUlEKzEUMBIGA1UEAwwLUHJvY2l2aXMgQUcxIjAgBgkqhkiG9w0BCQEW
E3N1cHBvcnRAcHJvY2l2aXMuY2gwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEK
AoIBAQC12c/oJkbdvxEO/aNKxKbpZRPk54eGSm9b2OG+Bm1SKgL+Kv/mhJaBEmSm
7HNeU/3QN2QGeKuie134YiXtNSb44REQI/Y0zNWHsMgYljGHRqlds0rk1E1B5vGt
tAgKLjXW6Kqo+jjDk3u0xi3edGmkBqLg5C8ZPyd8HqaaNdTNYQ5K3mSvCyNsk/w0
5TXpbtjUoUzRtrjrZfb4h1oiAQ/lAg3i4q49XDW/tVhslSiHElqxq70UNDGCa7Bp
e/WtOSW9NPAewmkx/9ErMp9jtM2FKqxh6sV0fK5c+xfZjoCEcsoe3upA25qXoPfC
p2gdjwzjicGz6O3O9UULwJU6HJV/AgMBAAEwDQYJKoZIhvcNAQELBQADggEBAD9q
zPbqXb1ZOy25vf6OMKqty+f2lhmTNFkDHSQRaa7FPytrRx+nvH+ZsaKSd7yTFUU8
apTNhbKda912dsKR+rdL/O3JXzAFM3yVzwPjmk5npV0YXYPnNxA/RzB2hc3e3+8U
MGAMha9B50+XUv4hI0fgJLqli2j9ifitI9rjRaVW+DT4M2g4NcKgG0W28XBhjdTD
b9FEisnAIYasLj6f6wIJa4p06USZfzk85hvVipnXI2cpWx93U1JUAUC0JqHRkuSP
3REmXuL5f0hpLVYcmlhm+VRBEBwIfcSGk5SE2L+OpovRbRiV7ZX5wyqtGcrTbwz8
gSPa7W5BWl9qPeMcZlU=
-----END CERTIFICATE-----
`];
