import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import * as actions from './actions';
export const reducer = combineReducers({
    file: (state = null, action) => {
        switch (action.type) {
            case getType(actions.receiveDocument):
                return action.document;
            default:
                return state;
        }
    },
    sig: (state = null, action) => {
        switch (action.type) {
            case getType(actions.receiveSig):
                return action.sig;
            default:
                return state;
        }
    },
});
