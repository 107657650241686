import { __awaiter } from "tslib";
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, FormattedDate } from 'react-intl';
import config, { getConfig } from '../config';
import { documentActions } from '../features/document';
import { changeLanguage } from '../features/root-action';
import { langCodes } from '../features/constants';
const messages = defineMessages({
    title: {
        id: 'pdfcheck.title',
        defaultMessage: 'This validator is a service of the Schaffhausen Administration',
    },
    uploadDoc: {
        id: 'pdfcheck.uploadDoc',
        defaultMessage: 'Upload document',
    },
    selectFile: {
        id: 'pdfcheck.selectFile',
        defaultMessage: 'Please select an electronically signed file that you would like to check:',
    },
    hintDragNDrop: {
        id: 'pdfcheck.hintDragNDrop',
        defaultMessage: 'Hint: You can also use drag & drop.',
    },
    invalidSignature: {
        id: 'pdfcheck.invalidSignature',
        defaultMessage: 'Invalid signature: {err}',
    },
    documentProblems: {
        id: 'pdfcheck.documentProblems',
        defaultMessage: 'There are problems with the document',
    },
    unknownCertificate: {
        id: 'pdfcheck.unknownCertificate',
        defaultMessage: 'The certificate issuer is unknown',
    },
    noSignature: {
        id: 'pdfcheck.noSignature',
        defaultMessage: 'No signature found',
    },
    documentSigned: {
        id: 'pdfcheck.documentSigned',
        defaultMessage: 'Document is signed',
    },
    document: {
        id: 'pdfcheck.document',
        defaultMessage: 'Document',
    },
    loading: {
        id: 'pdfcheck.loading',
        defaultMessage: 'Loading',
    },
    info: {
        id: 'pdfcheck.info',
        defaultMessage: 'Here you can check electronically signed documents.  If an official signature is attached to the document it will be displayed here.',
    },
    contact: {
        id: 'pdfcheck.contact',
        defaultMessage: 'Contact',
    },
    happyToHelp: {
        id: 'pdfcheck.happyToHelp',
        defaultMessage: 'Please contact us if you have any further questions regarding the electronic signature or validation of documents. We will be happy to help you',
    },
    blockchainTimestamp: {
        id: 'pdfcheck.blockchainTimestamp',
        defaultMessage: 'Blockchain Timestamp',
    },
    recordedOnBlockchain: {
        id: 'pdfcheck.recordedOnBlockchain',
        defaultMessage: 'Document is recorded on the blockchain.',
    },
    created: {
        id: 'pdfcheck.created',
        defaultMessage: 'Created',
    },
    firstName: {
        id: 'pdfcheck.firstName',
        defaultMessage: 'First name: {firstName}',
    },
    lastName: {
        id: 'pdfcheck.lastName',
        defaultMessage: 'Last name: {lastName}',
    },
    butNoClaims: {
        id: 'pdfcheck.butNoClaims',
        defaultMessage: 'But there are no claims present',
    },
    digitalSignature: {
        id: 'pdfcheck.digitalSignature',
        defaultMessage: 'Digital Signature',
    },
    validCaps: {
        id: 'pdfcheck.validCaps',
        defaultMessage: 'VALID',
    },
    signer: {
        id: 'pdfcheck.signer',
        defaultMessage: 'Signer',
    },
    certificate: {
        id: 'pdfcheck.certificate',
        defaultMessage: 'Certificate',
    },
    issuedBy: {
        id: 'pdfcheck.issuedBy',
        defaultMessage: 'Issued by',
    },
    validFromTo: {
        id: 'pdfcheck.validFromTo',
        defaultMessage: 'Valid from {validFrom} to {validTo}',
    },
    valid: {
        id: 'pdfcheck.valid',
        defaultMessage: 'VALID',
    },
    unknown: {
        id: 'pdfcheck.unknown',
        defaultMessage: 'UNKNOWN',
    },
});
export class PdfCheck extends React.Component {
    constructor(props) {
        super(props);
        this.renderTimestamp = (sig, idx) => {
            const ts = this.formatDate(new Date(sig.timestamp * 1000));
            return (React.createElement("div", { key: `signature-${idx}` },
                React.createElement("hr", null),
                React.createElement("p", null,
                    React.createElement("b", null,
                        React.createElement(FormattedMessage, Object.assign({}, messages.blockchainTimestamp))),
                    React.createElement("br", null),
                    React.createElement(FormattedMessage, Object.assign({}, messages.recordedOnBlockchain))),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, Object.assign({}, messages.created)),
                    ":",
                    React.createElement("br", null),
                    ts,
                    React.createElement("br", null))));
        };
        this.renderCustomSignature = (signature, index) => {
            const created = this.formatDate(new Date(signature.created * 1000));
            let claimsJSX;
            if (signature.hasOwnProperty('claims')) {
                claimsJSX = (React.createElement("p", null,
                    React.createElement(FormattedMessage, Object.assign({}, messages.firstName, { values: { firstName: signature.claims.firstName.data } })),
                    React.createElement("br", null),
                    React.createElement(FormattedMessage, Object.assign({}, messages.lastName, { values: { lastName: signature.claims.lastName.data } })),
                    React.createElement("br", null)));
            }
            else {
                claimsJSX = (React.createElement("p", null,
                    React.createElement(FormattedMessage, Object.assign({}, messages.butNoClaims)),
                    " ",
                    React.createElement("br", null)));
            }
            return (React.createElement("div", { key: `signature-${index}` },
                React.createElement("hr", null),
                React.createElement("p", null,
                    React.createElement("b", null,
                        React.createElement(FormattedMessage, Object.assign({}, messages.digitalSignature))),
                    React.createElement("br", null),
                    React.createElement(FormattedMessage, Object.assign({}, messages.validCaps))),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, Object.assign({}, messages.created)),
                    React.createElement("br", null),
                    created,
                    React.createElement("br", null)),
                claimsJSX));
        };
        this.renderx509Signature = (signature, index) => {
            const { meta } = signature;
            const validFrom = this.formatDate(new Date(meta.validFrom));
            const validTo = this.formatDate(new Date(meta.validTo));
            const verified = meta.verified;
            const verifiedValue = verified ? messages.valid : messages.unknown;
            return (React.createElement("div", { key: `signature-${index}` },
                React.createElement("hr", null),
                React.createElement("p", null,
                    React.createElement("b", null,
                        React.createElement(FormattedMessage, Object.assign({}, messages.digitalSignature))),
                    React.createElement("br", null),
                    React.createElement(FormattedMessage, Object.assign({}, verifiedValue))),
                React.createElement("p", null,
                    React.createElement("b", null,
                        React.createElement(FormattedMessage, Object.assign({}, messages.signer))),
                    React.createElement("br", null),
                    signature.meta.commonName,
                    React.createElement("br", null)),
                React.createElement("p", null,
                    React.createElement("b", null,
                        React.createElement(FormattedMessage, Object.assign({}, messages.certificate))),
                    " ",
                    React.createElement("br", null),
                    React.createElement(FormattedMessage, Object.assign({}, messages.issuedBy)),
                    ": ",
                    signature.meta.issuer,
                    React.createElement("br", null),
                    React.createElement(FormattedMessage, Object.assign({}, messages.validFromTo, { values: { validFrom: validFrom, validTo: validTo } })),
                    React.createElement("br", null))));
        };
        this.formatDate = (date) => {
            return (React.createElement(FormattedDate, { value: date }));
        };
        this.changeLanguageEvent = (event) => {
            this.props.changeLanguage(event.target.value);
        };
        this.getLanguagePicker = () => {
            const languageOptions = langCodes.map((lc) => (React.createElement("option", { value: lc.short, key: lc.short }, lc.long)));
            return (React.createElement(React.Fragment, null,
                React.createElement("select", { className: "custom-select custom-select-sm", onChange: this.changeLanguageEvent, defaultValue: this.props.language, key: "languageSelect" }, languageOptions)));
        };
        this.handleChange = (evt) => {
            this.props.setSig(null);
            this.setState({ name: undefined });
            const file = evt.target.files[0];
            const reader = new FileReader();
            reader.onload = this.handleLoad(evt.target.files[0].name);
            reader.readAsArrayBuffer(file);
        };
        this.handleLoad = (name) => {
            return (result) => {
                this.setState({ name });
                const content = result.target.result;
                this.props.onFile(content);
            };
        };
        this.state = { config: config };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const cfg = yield getConfig();
            this.setState({ config: cfg });
        });
    }
    render() {
        const { signatures } = this.props;
        let result;
        if (!this.state.name) {
            result = (React.createElement(React.Fragment, null,
                React.createElement("h2", null,
                    React.createElement(FormattedMessage, Object.assign({}, messages.uploadDoc))),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, Object.assign({}, messages.selectFile))),
                React.createElement("div", { className: "upload-state" }),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, Object.assign({}, messages.hintDragNDrop)))));
        }
        else if (signatures !== null) {
            const sigs = signatures.map((signature, index) => {
                if (signature.err) {
                    return (React.createElement("div", { key: `signature-${index}` },
                        React.createElement("hr", null),
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, Object.assign({}, messages.invalidSignature, { values: { err: (React.createElement(FormattedMessage, Object.assign({}, signature.err))) } })))));
                }
                else {
                    if (signature.certificate) {
                        return this.renderx509Signature(signature, index);
                    }
                    else if (signature.timestamp !== undefined) {
                        return this.renderTimestamp(signature, index);
                    }
                    else {
                        return this.renderCustomSignature(signature, index);
                    }
                }
            });
            const noSig = signatures.length === 0;
            const error = signatures.some((s) => s.err);
            const unknown = signatures.some((s) => s.meta && !s.meta.verified);
            const status = error ? messages.documentProblems :
                unknown ? messages.unknownCertificate :
                    noSig ? messages.noSignature :
                        messages.documentSigned;
            const icon = error ? 'upload-state-not' :
                unknown ? 'upload-state-unknown' :
                    noSig ? 'upload-state-not' :
                        'upload-state-ok';
            result = (React.createElement(React.Fragment, null,
                React.createElement("h2", null,
                    React.createElement(FormattedMessage, Object.assign({}, status))),
                React.createElement("div", { className: icon }),
                React.createElement("p", null,
                    React.createElement("b", null,
                        React.createElement(FormattedMessage, Object.assign({}, messages.document))),
                    " ",
                    React.createElement("br", null),
                    this.state.name),
                sigs));
        }
        else {
            result = (React.createElement(React.Fragment, null,
                React.createElement("h2", null,
                    React.createElement(FormattedMessage, Object.assign({}, messages.loading))),
                React.createElement("div", { className: "upload-state-loading" }),
                React.createElement("p", null,
                    React.createElement("b", null,
                        React.createElement(FormattedMessage, Object.assign({}, messages.document))),
                    " ",
                    React.createElement("br", null),
                    this.state.name)));
        }
        const languagePicker = this.getLanguagePicker();
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 col-md-2" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-md-12 col-6" },
                                React.createElement("div", { className: "sh-logo" })),
                            React.createElement("div", { className: "col-md-12 col-6" }, languagePicker))),
                    React.createElement("div", { className: "col-12 col-md-6" },
                        React.createElement("h1", null,
                            React.createElement(FormattedMessage, Object.assign({}, messages.title))),
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, Object.assign({}, messages.info)))),
                    React.createElement("div", { className: "col-10 col-md-6 mt-4 offset-1 offset-md-2 text-center upload-field" },
                        React.createElement("input", { className: "upload-field-button", type: "file", onChange: this.handleChange }),
                        result),
                    React.createElement("div", { className: "col-12 col-md-3 offset-0 offset-md-1 mt-4" },
                        React.createElement("h2", null,
                            React.createElement(FormattedMessage, Object.assign({}, messages.contact))),
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, Object.assign({}, messages.happyToHelp)),
                            ":"),
                        React.createElement("a", { href: 'mailto:' + this.state.config.supportEmailAddress },
                            React.createElement("button", { type: "button", className: "btn btn-warning" }, this.state.config.supportEmailAddress))))),
            React.createElement("footer", { className: "footer" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 col-md-6" },
                        React.createElement("p", { className: "text-center text-md-left" }, "\u00A9 2020 Procivis AG"))),
                React.createElement("div", { className: "sh-footer-yellow" }),
                React.createElement("div", { className: "sh-footer-black" }))));
    }
}
const mapStateToProps = (state) => ({
    signatures: state.document.sig,
    language: state.language,
});
const mapDispatchToProps = {
    onFile: documentActions.loadDocument,
    setSig: documentActions.receiveSig,
    changeLanguage: changeLanguage,
};
export default connect(mapStateToProps, mapDispatchToProps)(PdfCheck);
