import { defineMessages } from 'react-intl';
const messages = defineMessages({
    signatureRangeMissing: {
        id: 'parser.signatureRangeMissing',
        defaultMessage: 'Signature range is missing',
    },
    byteRangeMissing: {
        id: 'parser.byteRangeMissing',
        defaultMessage: 'Failed to locate the end of the ByteRange',
    },
    byteRangeParsingFailed: {
        id: 'parser.byteRangeParsingFailed',
        defaultMessage: 'Failed to parse the signature range',
    },
});
export class VerificationError extends Error {
    constructor(msg) {
        super(msg.defaultMessage);
        this.getMessage = () => this.msg;
        this.msg = msg;
    }
}
function parseByteRange(pdf, byteRangeIndex) {
    const byteRangePos = pdf.indexOf('[', byteRangeIndex);
    if (byteRangePos === -1) {
        throw new VerificationError(messages.signatureRangeMissing);
    }
    const byteRangeEnd = pdf.indexOf(']', byteRangePos);
    if (byteRangeEnd === -1) {
        throw new VerificationError(messages.byteRangeMissing);
    }
    const byteRange = pdf.slice(byteRangePos, byteRangeEnd + 1).toString();
    const matches = (/\[\s*(\d+)\s*(\d+)\s*(\d+)\s*(\d+)\s*\]/).exec(byteRange);
    if (!matches) {
        throw new VerificationError(messages.byteRangeParsingFailed);
    }
    const signedData = Buffer.concat([
        pdf.slice(parseInt(matches[1], 10), parseInt(matches[1], 10) + parseInt(matches[2], 10)),
        pdf.slice(parseInt(matches[3], 10), parseInt(matches[3], 10) + parseInt(matches[4], 10)),
    ]);
    let signatureHex = pdf.slice(parseInt(matches[1], 10) + parseInt(matches[2], 10) + 1, parseInt(matches[3], 10) - 1).toString('binary');
    const signature = Buffer.from(signatureHex, 'hex').toString('binary');
    return { ByteRange: matches.slice(1, 5).map(Number), signature, signedData };
}
export const extractSignatures = (pdf) => {
    const signatures = [];
    let previous = 0;
    let byteRangeEntry;
    while ((byteRangeEntry = pdf.indexOf('/ByteRange', previous)) > 0) {
        signatures.push(parseByteRange(pdf, byteRangeEntry));
        previous = byteRangeEntry + 1;
    }
    return signatures;
};
