import { __awaiter } from "tslib";
import axios from 'axios';
import config from '../../config';
import { defineMessages } from 'react-intl';
const messages = defineMessages({
    signatureRevoked: {
        id: 'revocation.signatureRevoked',
        defaultMessage: 'Signature is revoked',
    },
    issuerNotTrusted: {
        id: 'revocation.issuerNotTrusted',
        defaultMessage: 'Issuer of the identity is not trusted',
    },
});
export function checkRevocation(sig) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = config.gatewayUrl + '/api/internal/revocations';
        if (!sig.hasOwnProperty('claims')) {
            console.error('Missing claims property, skipping revocation');
            return sig;
        }
        for (const claimKey of Object.keys(sig.claims)) {
            const claim = sig.claims[claimKey];
            const sigHex = claim.signature;
            const issuer = claim.issuer;
            yield new Promise((resolve) => setTimeout(resolve, 200));
            const r = yield axios.post(url, { sig: sigHex, issuer });
            const data = r.data;
            console.log('Revocation status:', data);
            if (data === true || data.revoked) {
                return {
                    sig,
                    err: messages.signatureRevoked,
                };
            }
            if (data === false || data.trusted !== true) {
                return {
                    sig,
                    err: messages.issuerNotTrusted,
                };
            }
        }
        return sig;
    });
}
