import { extractSignatures, VerificationError } from './parser';
import forge from 'node-forge';
import crypto from 'crypto';
import { verifyCertificate } from './certificate';
import { defineMessages } from 'react-intl';
const messages = defineMessages({
    invalidAttributes: {
        id: 'verifier.invalidAttributes',
        defaultMessage: 'Invalid signature attributes',
    },
    wrongDigest: {
        id: 'verifier.wrongDigest',
        defaultMessage: 'Wrong content digest',
    },
    insufficientRange: {
        id: 'verifier.insufficientRange',
        defaultMessage: 'Signature range is insufficient: the document signed only partially or has been tampered with',
    },
    genericError: {
        id: 'verifier.genericError',
        defaultMessage: '{raw_error}',
    },
});
function x509verifySignature(signatureMeta, pdfBuffer) {
    const { signature, signedData, ByteRange } = signatureMeta;
    const p7Asn1 = forge.asn1.fromDer(signature);
    const p = forge.pkcs7;
    const message = p.messageFromAsn1(p7Asn1);
    const attrs = message.rawCapture.authenticatedAttributes;
    const hashAlgorithmOid = forge.asn1.derToOid(message.rawCapture.digestAlgorithm);
    const hashAlgorithm = forge.pki.oids[hashAlgorithmOid].toLowerCase();
    const cert = forge.pki.certificateToPem(message.certificates[0]);
    const sig = message.rawCapture.signature;
    const set = forge.asn1.create(forge.asn1.Class.UNIVERSAL, forge.asn1.Type.SET, true, attrs);
    const buf = Buffer.from(forge.asn1.toDer(set).data, 'binary');
    const verifier = crypto.createVerify(`RSA-${hashAlgorithm.toUpperCase()}`);
    const validAuthAttrs = verifier.update(buf).verify(cert, sig, 'latin1');
    if (!validAuthAttrs) {
        throw new VerificationError(messages.invalidAttributes);
    }
    const messageDigestOid = forge.pki.oids.messageDigest;
    const fullAttrDigest = attrs.find((attr) => forge.asn1.derToOid(attr.value[0].value) === messageDigestOid);
    const attrDigest = fullAttrDigest.value[1].value[0].value;
    const dataDigest = crypto.createHash(hashAlgorithm).update(signedData).digest();
    const validContentDigest = dataDigest.toString('binary') === attrDigest;
    if (!validContentDigest) {
        throw new VerificationError(messages.wrongDigest);
    }
    if (ByteRange[0] !== 0 || ByteRange[2] + ByteRange[3] !== pdfBuffer.length) {
        throw new VerificationError(messages.insufficientRange);
    }
    const c = message.certificates[0];
    const meta = {
        commonName: c.subject.attributes.find((e) => e.name === 'commonName').value,
        validFrom: c.validity.notBefore,
        validTo: c.validity.notAfter,
        issuer: c.issuer.attributes.find((e) => e.name === 'commonName').value,
        verified: verifyCertificate(cert),
    };
    return { meta, certificate: cert };
}
export function x509verify(pdfBuffer) {
    const signatures = [];
    let foundSignatures;
    try {
        foundSignatures = extractSignatures(pdfBuffer);
    }
    catch (err) {
        if (err instanceof VerificationError) {
            return [{ err: err.getMessage() }];
        }
        else {
            return [{ err: Object.assign(Object.assign({}, messages.genericError), { values: { raw_error: err.message } }) }];
        }
    }
    foundSignatures.forEach(sig => {
        try {
            const result = x509verifySignature(sig, pdfBuffer);
            signatures.push(result);
        }
        catch (err) {
            let error;
            if (err instanceof VerificationError) {
                error = { err: err.getMessage() };
            }
            else {
                error = {
                    err: Object.assign(Object.assign({}, messages.genericError), { values: { raw_error: err.message } }),
                };
            }
            signatures.push(error);
        }
    });
    return signatures;
}
