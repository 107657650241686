export const langCodes = [
    {
        short: 'de',
        long: 'Deutsch'
    },
    {
        short: 'en',
        long: 'English'
    }
];
function getDefaultLang() {
    const browserDefault = navigator.language.split(/[-_]/)[0];
    if (langCodes.some((lc) => lc.short === browserDefault)) {
        return browserDefault;
    }
    else {
        return 'en';
    }
}
export const browserDefaultLang = getDefaultLang();
