import { __awaiter } from "tslib";
import { createCustomAction } from 'typesafe-actions';
import { getSignatures } from './parser';
import { x509verify } from './x509/verifier';
import { checkRevocation } from './revocation';
import { x509checkRevocation } from './x509/revocation';
import config from '../../config';
import { defineMessages } from 'react-intl';
const messages = defineMessages({
    revocationFailed: {
        id: 'actions.revocationFailed',
        defaultMessage: 'revocation check failed',
    },
});
const RECEIVE_DOCUMENT = 'RECEIVE_DOCUMENT';
const RECEIVE_SIG = 'RECEIVE_SIG';
export const receiveDocument = createCustomAction(RECEIVE_DOCUMENT, type => {
    return (document) => ({ type, document });
});
export const receiveSig = createCustomAction(RECEIVE_SIG, type => {
    return (sig) => ({ type, sig });
});
export const loadDocument = (content) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(receiveDocument(content));
        const checkedSignatures = getSignatures(content);
        const checked = checkedSignatures.map(checkRevocation);
        if (config.x509 && config.x509.enabled) {
            const buffer = Buffer.from(content);
            const x509Signatures = x509verify(buffer);
            x509Signatures.map(x509checkRevocation).forEach((sig) => checked.push(sig));
        }
        try {
            const results = yield Promise.all(checked);
            dispatch(receiveSig(results));
        }
        catch (error) {
            const err = error.intl_err || messages.revocationFailed;
            dispatch(receiveSig([{ err }]));
        }
    });
};
