import { pki } from 'node-forge';
import ca from '../../../../configs/ca';
const caStore = pki.createCaStore();
ca.map(caStore.addCertificate);
export function verifyCertificate(cert) {
    const crt = pki.certificateFromPem(cert);
    try {
        pki.verifyCertificateChain(caStore, [crt]);
        return true;
    }
    catch (e) {
        return false;
    }
}
