import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as document } from './document/reducer';
import { browserDefaultLang } from './constants';
import { changeLanguage } from './root-action';
import { getType } from 'typesafe-actions';
export const rootReducer = (history) => {
    return combineReducers({
        router: connectRouter(history),
        document,
        language: (state = browserDefaultLang, action) => {
            if (action.type === getType(changeLanguage)) {
                return action.language;
            }
            return state;
        },
    });
};
